@import url(https://fonts.googleapis.com/css2?family=Merriweather&family=Poppins&family=Quattrocento&family=Staatliches&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
	border: none;
	background: none;
}
a {
	text-decoration: none;
}

@font-face {
  font-family: 'YokaiKingdom';
  font-weight: 500;
  font-style: normal;
  src: url(/static/media/YokaiKingdomV3-Regular.a58c4fe8.otf) format('opentype');
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  line-height: 26px;
}

p {
  line-height: 26px;
}

body {
  position: relative;
  background: #292929;
  overflow-x: hidden;
}
body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: -1;
  opacity: 0.3;
}

button {
  padding: 0;
  cursor: pointer;
}

button,
textarea,
input {
  font-family: 'Poppins', sans-serif;
}

.track-vertical {
  right: 2px;
  display: block !important;
  height: 100%;
  background: #101833;
  border-radius: 8px;
}

.thumb-vertical {
  right: 0;
  display: block !important;
  background: #00C0FF;
  border-radius: 8px;
}

