@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Poppins&family=Quattrocento&family=Staatliches&display=swap');


@font-face {
  font-family: 'YokaiKingdom';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/YokaiKingdomV3-Regular.otf') format('opentype');
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  line-height: 26px;
}

p {
  line-height: 26px;
}

body {
  position: relative;
  background: #292929;
  overflow-x: hidden;
}
body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: -1;
  opacity: 0.3;
}

button {
  padding: 0;
  cursor: pointer;
}

button,
textarea,
input {
  font-family: 'Poppins', sans-serif;
}

.track-vertical {
  right: 2px;
  display: block !important;
  height: 100%;
  background: #101833;
  border-radius: 8px;
}

.thumb-vertical {
  right: 0;
  display: block !important;
  background: #00C0FF;
  border-radius: 8px;
}
